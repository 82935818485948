import { render, staticRenderFns } from "./PlayerActivity.vue?vue&type=template&id=6352eac3&scoped=true&"
import script from "./PlayerActivity.vue?vue&type=script&lang=js&"
export * from "./PlayerActivity.vue?vue&type=script&lang=js&"
import style0 from "./PlayerActivity.vue?vue&type=style&index=0&id=6352eac3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6352eac3",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel});
